import * as types from "./types";
import {ApptSlotBlock} from "./types";
import {groupBy} from "lodash";
import moment from "moment-timezone";
import {MsMap} from "../constants/MsMap";
import {PracticeId} from "../constants/practiceIds";
import {defaultSlotQualificationIds} from "../constants/qualificationIds";
import {fetchCachedSlot} from "../utils/slots";
import {DEFAULT_TIMEZONE, api} from "./api";

export const loadAvailableSlots = ({
  practiceId = PracticeId.CARBON,
  from,
  to,
  duration = 10,
  qualificationIds = defaultSlotQualificationIds,
  timezone = DEFAULT_TIMEZONE,
  limit = 1,
  enableCache = false,
  ...rest
}: {
  practiceId?: string;
  locationId?: string;
  from?: number;
  to?: number;
  duration?: number;
  virtual?: boolean;
  specialtyId?: string;
  doctorId?: string;
  qualificationIds?: string[];
  includeWalkInOnly?: boolean;
  limit?: number;
  timezone?: string;
  reasonId?: string;
  enableCache?: boolean;
}): Promise<types.ApptSlotBlock[]> => {
  if (enableCache) {
    return fetchCachedSlot({
      practiceId,
      specialtyId: rest.specialtyId,
      locationId: rest.locationId,
      doctorId: rest.doctorId,
      reasonId: rest.reasonId,
      limit,
    }).then(slot => [slot ? [slot] : []]);
  }
  const days = 7;
  return api
    .post("/hib/getNextAvailableSlots", {
      practiceId,
      from: from || Date.now(),
      to: to || (from || Date.now()) + MsMap.ONE_DAY * days,
      duration,
      timezone,
      qualificationIds,
      limit,
      ...rest,
    })
    .then(r => r.json() as ApptSlotBlock)
    .then(slots => groupBy(slots, slot => moment(slot.time).format("YYYY-MM-DD")).vals()) // group by days and get values
    .then(days => days.map(slots => slots.sortBy("time").distinctBy("time")))
    .catch(e => {
      console.error(e);
      return [];
    });
};
